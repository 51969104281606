<template>
  <div class="sf-product-card" :class="{ 'has-colors': colors.length }" data-testid="product-card">
    <div class="sf-product-card__image-wrapper">
      <p class="badges-list">
        <span class="teaser-badge is_new-badge" v-if="product && product.is_new">{{ $t('New') }}</span>
        <span class="teaser-badge engraving_enabled-badge" v-if="product && product.engraving_enabled">{{
          $t('Engraving')
        }}</span>
        <span
          class="teaser-badge is_on_sale-badge"
          v-if="(product && product.is_on_sale) || (specialPrice && regularPrice && specialPrice < regularPrice)"
          >{{ $t('Sale') }}</span
        >
      </p>
      <slot
        name="image"
        v-bind="{
          image,
          title,
          link,
          imageHeight,
          imageWidth,
          imageTag,
          nuxtImgConfig,
        }"
      >
        <SfButton
          :link="localizedLink"
          class="sf-button--pure sf-product-card__link"
          data-testid="product-link"
          aria-label="Go To Product"
          v-on="$listeners"
        >
          <template v-if="Array.isArray(image)">
            <SfImage
              v-for="(picture, key) in image.slice(0, 2)"
              :key="key"
              class="sf-product-card__picture"
              :src="picture"
              :alt="title"
              :width="imageWidth"
              :height="imageHeight"
              :image-tag="imageTag"
              :nuxt-img-config="nuxtImgConfig"
              @click="triggerGtagEvent"
            />
          </template>
          <SfImage
            v-else
            class="sf-product-card__image"
            :src="image"
            :alt="title"
            :width="imageWidth"
            :height="imageHeight"
            :image-tag="imageTag"
            :nuxt-img-config="nuxtImgConfig"
            @click="triggerGtagEvent"
          />
        </SfButton>
      </slot>
      <slot name="badge" v-bind="{ badgeLabel, badgeColor }">
        <SfBadge class="sf-product-card__badge" :class="[badgeColorClass, { 'display-none': !badgeLabel }]">{{
          badgeLabel
        }}</SfBadge>
      </slot>
      <SfButton
        :aria-label="`${ariaLabel} ${title}`"
        :class="[wishlistIconClasses, { 'display-none': !wishlistIcon }]"
        data-testid="product-wishlist-button"
        @click="toggleIsInWishlist"
        class="btn btn-wishlist"
      >
        <slot name="wishlist-icon" v-bind="{ currentWishlistIcon }">
          <i class="icon-heart-full" v-if="currentWishlistIcon !== false"> </i>
        </slot>
      </SfButton>
      <div :class="{ 'display-none': !showAddToCartButton }">
        <slot
          name="add-to-cart"
          v-bind="{
            isAddedToCart,
            showAddedToCartBadge,
            isAddingToCart,
            title,
          }"
        >
          <SfCircleIcon
            class="sf-product-card__add-button"
            :class="{ 'has-colors': colors.length }"
            :aria-label="`Add to Cart ${title}`"
            :has-badge="showAddedToCartBadge"
            :disabled="addToCartDisabled"
            data-testid="product-add-icon"
            @click="onAddToCart"
          >
            <span class="sf-product-card__add-button--icons">
              <transition v-if="!isAddingToCart && !isAddedToCart" name="sf-pulse" mode="out-in">
                <slot name="add-to-cart-icon">
                  <SfIcon key="add_to_cart" icon="add_to_cart" size="20px" color="white" />
                </slot>
              </transition>
              <transition v-else name="sf-pulse" mode="out-in">
                <slot name="adding-to-cart-icon">
                  <SfIcon key="added_to_cart" icon="added_to_cart" size="20px" color="white" />
                </slot>
              </transition>
            </span>
          </SfCircleIcon>
        </slot>
      </div>
    </div>
    <slot name="title" v-bind="{ title, link }">
      <SfButton
        :link="localizedLink"
        class="sf-button--pure sf-product-card__link"
        data-testid="product-link"
        v-on="$listeners"
      >
        <span class="sf-product-card__title" @click="triggerGtagEvent">
          {{ title }}
        </span>
      </SfButton>
    </slot>
    <slot name="price" v-bind="{ specialPrice, regularPrice }">
      <span class="price-from-label" v-if="product.__typename === 'ConfigurableProduct'">{{ $t('Price from') }} </span
      ><SfPrice
        :class="{ 'display-none': !regularPrice }"
        class="sf-product-card__price"
        :regular="regularPrice"
        :special="specialPrice"
      />
    </slot>
    <slot name="colors" v-bind="{ colors }">
      <div class="sf-product-card__color-wrap">
        <SfColor
          v-for="(color, i) in colors"
          :key="color.value"
          :color="color.color"
          :selected="color.selected"
          class="sf-product-card__color"
          :class="{ 'display-none': i > 10 && showBadge }"
          @click="handleSelectedColor(i)"
        />
        <span v-if="showBadge" class="sf-product-card__colorsBadge">+</span>
      </div>
    </slot>
    <slot name="reviews" v-bind="{ maxRating, scoreRating }">
      <div :class="{ 'display-none': !scoreRating }" class="sf-product-card__reviews">
        <SfRating
          v-if="typeof scoreRating === 'number'"
          class="sf-product-card__rating"
          :max="maxRating"
          :score="scoreRating"
        />
        <SfButton
          :class="{ 'display-none': !reviewsCount }"
          :aria-label="`Read ${reviewsCount} reviews about ${title}`"
          class="sf-button--pure sf-product-card__reviews-count"
          data-testid="product-review-button"
          @click="$emit('click:reviews')"
        >
          ({{ reviewsCount }})
        </SfButton>
      </div>
    </slot>
  </div>
</template>
<script>
import { defineComponent, useContext, computed } from '@nuxtjs/composition-api';
import { colorsValues as SF_COLORS } from '@storefront-ui/shared/variables/colors';
import {
  SfPrice,
  SfRating,
  SfIcon,
  SfImage,
  SfCircleIcon,
  SfBadge,
  SfButton,
  SfColorPicker,
  SfColor,
} from '@storefront-ui/vue';

export default defineComponent({
  name: 'PafiProductCard',
  components: {
    SfPrice,
    SfRating,
    SfIcon,
    SfImage,
    SfCircleIcon,
    SfBadge,
    SfButton,
    SfColorPicker,
    SfColor,
  },
  props: {
    product: {
      type: [Array, Object, String],
      default: '',
    },
    image: {
      type: [Array, Object, String],
      default: '',
    },
    imageWidth: {
      type: [Number, String],
      default: null,
    },
    imageHeight: {
      type: [Number, String],
      default: null,
    },
    badgeLabel: {
      type: String,
      default: '',
    },
    badgeColor: {
      type: String,
      default: '',
    },
    colors: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    link: {
      type: [String, Object],
      default: null,
    },
    /**
     * Link element tag
     * @deprecated will be removed in 1.0.0 use slot to replace content
     */
    linkTag: {
      type: String,
      default: undefined,
    },
    scoreRating: {
      type: [Number, Boolean],
      default: false,
    },
    reviewsCount: {
      type: [Number, Boolean],
      default: false,
    },
    maxRating: {
      type: [Number, String],
      default: 5,
    },
    regularPrice: {
      type: [Number, String],
      default: null,
    },
    specialPrice: {
      type: [Number, String],
      default: null,
    },
    wishlistIcon: {
      type: [String, Array, Boolean],
      default: 'heart-fill',
    },
    isInWishlistIcon: {
      type: [String, Array],
      default: 'heart_fill',
    },
    isInWishlist: {
      type: Boolean,
      default: false,
    },
    showAddToCartButton: {
      type: Boolean,
      default: false,
    },
    isAddedToCart: {
      type: Boolean,
      deafult: false,
    },
    addToCartDisabled: {
      type: Boolean,
      default: false,
    },
    imageTag: {
      type: String,
      default: '',
    },
    nuxtImgConfig: {
      type: Object,
      default: () => ({}),
    },
    catName: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: undefined,
    },
  },
  setup(props) {
    const { app, localePath } = useContext();
    const triggerGtagEvent = async () => {
      app.$gtm.push({ ecommerce: null });
      app.$gtm.push({
        event: 'select_item',
        ecommerce: {
          item_list_id: props.catName.replace(' ', '_').toLowerCase(),
          item_list_name: props.catName,
          items: [
            {
              item_id: props.product.sku,
              item_name: props.product.name,
              index: props.index,
              item_brand: props.product.custom_attributes?.find(
                (attr) => attr.attribute_metadata.code === 'manufacturer',
              )?.selected_attribute_options?.attribute_option[0]?.label,
              ...props.product.categories.reduce((acc, category, index) => {
                acc[`item_category${index + 1}`] = category.name;
                return acc;
              }, {}),
              item_list_id: props.catName.replace(' ', '_').toLowerCase(),
              item_list_name: props.catName,
              price: props.regularPrice
                ? parseFloat(props.regularPrice.replace(' €', '').replace(/\./g, '').replace(/,/g, '.'))
                : '',
              discount: props.specialPrice
                ? parseFloat(props.specialPrice.replace(' €', '').replace(/\./g, '').replace(/,/g, '.'))
                : '',
            },
          ],
        },
      });
    };
    const localizedLink = computed(() => {
      return localePath(decodeURIComponent(props.link.path));
    });
    return {
      triggerGtagEvent,
      localizedLink,
    };
  },
  data() {
    return {
      isAddingToCart: false,
      openColorPicker: false,
    };
  },
  computed: {
    isSFColors() {
      return SF_COLORS.includes(this.badgeColor.trim());
    },
    badgeColorClass() {
      return this.isSFColors ? `${this.badgeColor.trim()}` : '';
    },
    currentWishlistIcon() {
      return this.isInWishlist ? this.isInWishlistIcon : this.wishlistIcon;
    },
    showAddedToCartBadge() {
      return !this.isAddingToCart && this.isAddedToCart;
    },
    ariaLabel() {
      return this.isInWishlist ? 'Remove from wishlist' : 'Add to wishlist';
    },
    wishlistIconClasses() {
      const defaultClass = 'sf-button--pure sf-product-card__wishlist-icon';
      return `${defaultClass} ${this.isInWishlist ? 'on-wishlist' : ''}`;
    },
    showBadge() {
      return this.colors.length > 10;
    },
  },
  methods: {
    toggleIsInWishlist() {
      this.$emit('click:wishlist', !this.isInWishlist);
    },
    onAddToCart(event) {
      event.preventDefault();
      this.isAddingToCart = true;
      setTimeout(() => {
        this.isAddingToCart = false;
      }, 1000);
      this.$emit('click:add-to-cart');
    },
    handleSelectedColor(colorIndex) {
      if (this.colors.length > 0) {
        this.colors.map((color, i) => {
          if (colorIndex === i) {
            this.$emit('click:colors', color);
            this.openColorPicker = false;
          }
        });
      }
    },
    toggleColorPicker() {
      this.openColorPicker = !this.openColorPicker;
    },
  },
});
</script>
<style lang="scss" scoped>
.sf-product-card {
  &__color-wrap {
    display: flex;
    gap: 5px;
    padding: 15px;
  }

  &__color {
    border-radius: 100%;
    margin: 0;
    width: 8px;
    height: 8px;
    background-position: center;
    background-size: contain;
    cursor: default;

    &:hover {
      transform: none;
    }
  }

  &__colorsBadge {
    margin: 0;
    color: #222222;
    line-height: 0.5;
  }

  &__wishlist-icon {
    opacity: 1;
    padding: 5px;
    border: 1px solid #dddddd;

    &.on-wishlist {
      background: #222222;
      border-color: #222222;
      --icon-color: #fff;
    }
  }

  .badges-list {
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 9;
  }

  span.teaser-badge {
    background: #000;
    color: #fff;
    font-size: 12px;
    text-align: center;
    padding: 5px 10px;
    display: block;
    width: max-content;
    margin: 0 0 10px 0px;
    line-height: initial;
  }

  span.is_new-badge {
    background: #000;
  }

  span.is_on_sale-badge {
    background: #000;
  }

  span.engraving_enabled-badge {
    background: #000;
  }
}
</style>
